<template>
  <div>
    <Header></Header>
    <div id="window">
    <div class="cycle"
    @mouseenter="stop"
    @mouseleave="start" >
      <div class="content" :style="changePic">
        <img
          v-for="(img_url, key) in img_src"
          :key="key"
          ref="imgs"
          :src="img_url.url"
          alt="这是轮播图"
        />
      </div>

      <div class="btn">
        <button class="left" @click="go(1)" href="#">
          <p>向左</p>
        </button>
        <button class="right" @click="go(-1)" href="#">
          <p>向右</p>
        </button>
      </div>

      <div class="bottom">
        <span
          v-for="(li, key) in img_src"
          :key="key"
          :class="{ active: key === index }"
        >
        </span>
      </div>
    </div>
  </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "@/views/header.vue";
import Footer from "@/views/footer.vue";
export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      img_src: [
        { url: require("../../assets/lunbotu.png") },
        { url: require("../../assets/lunbotu2.png") },
        { url: require("../../assets/lunbotu3.png") },
      ],
      index: 0, //现在是第几张
      time: 1500, //设置循环时间
      width: 0, //移动的长度
    };
  },
    computed: {
    changePic() {
      return {
        transform: `translate3d(${this.width}px, 0, 0)`, //主要实现核心
      };
    },
  },
  mounted(){
    console.log('挂载完成');
    this.start();
  },
  beforeDestroy() {
    this.stop();
  },
 methods: {
    stop() {
      clearInterval(this.timer);
    },
    start() {
      this.timer = setInterval(() => {
        this.go(-1), console.log("开始计时");
      }, 1000);
    },

    go(direc) {
      console.log(direc);
      if (direc == -1) {
        this.index = this.index >= this.img_src.length - 1 ? 0 : this.index + 1;
      } else if (direc == 1) {
        this.index = this.index <= 0 ? this.img_src.length - 1 : this.index - 1;
      }
      console.log("index:", this.index);
      this.move(); //移动
    },
    move() {
      this.width = -400 * this.index;
      console.log("width:", this.width);
    },
  },
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}
#window {
  width: 100%;
}
.cycle {
  width: 100vw;
  height: 100vh;
  position: relative;
  top: 0;
  left: 0;
  margin: 0 auto;
  z-index: 10;
  overflow: hidden;
}
.content {
  display: flex;
  height: 100%;
  transition: 0.5s ease;
}
.content img {
    width: 100vw;
    height: 100vh;
  border-radius: 10px;
}
.cycle button {
  width: 40px;
  height: 80px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0.5;
  text-align: center;
}

.cycle .right {
  right: 0px;
}
.cycle .left {
  left: 0px;
}

.bottom {
  position: absolute;
  bottom: 10px;
  display: inline-block;
  transform: translateX(50%);
  right: 50%;
}
.bottom span {
  width: 14px;
  height: 14px;
  border-radius: 7px;
  background-color: rgba(110, 102, 102, 0.849);
  opacity: 0.4;
  margin: 10px;
  display: inline-block;
  /* span是行内元素 */
}
/* 圆圈圈激活后*/
.bottom .active {
  /*数值分别是：水平偏移，上下偏移，模糊，大小，颜色 */
  box-shadow: 0px 0px 2px 2px #53a8ff;
  background-color: #a0cfff !important;
  opacity: 0.6;
}

</style>
